import React, { useState } from "react";
import { useLocalStorage } from "./useLocalStorage";

function App() {
  const [name, setName] = useState("");
  const [time, setTime] = useState(1);
  const [valError, setValError] = useState({ text: false });
  const [isShown, setIsShown] = useState("");
  const [isHours, setIsHours] = useLocalStorage("isHours", false);
  const [counters, setCounters] = useLocalStorage("counters", {});

  const handleNew = (e) => {
    e.preventDefault();
    if (name !== "") {
      setCounters({ ...counters, [name]: Math.ceil((60 * time) / 25) });
      setName("");
      setValError({ text: false });
    }
  };

  const updateCounter = (counterName) => {
    setIsShown("");
    if (counters[counterName] <= 1) {
      const { [counterName]: tmp, ...newCounters } = counters;
      setCounters(newCounters);
      alert(`Great Job for finishing ${counterName}! 🎉`);
    } else {
      setCounters({ ...counters, [counterName]: counters[counterName] - 1 });
    }
  };

  const handleToggle = (e) => {
    const isChecked = e.target.checked;
    setIsHours(isChecked);
  };

  const updateName = (e) => {
    if (e.target.value.length <= 20) {
      setName(e.target.value);
      setValError({ ...valError, text: false });
    } else setValError({ ...valError, text: true });
  };

  const resetCounters = () => {
    if (window.confirm("This will reset all your counters.")) setCounters({});
  };

  const getRepr = (counter) => {
    const mins = counters[counter] * 25;
    const t_hrs = mins / 60;
    const r_hrs = Math.floor(t_hrs);
    const t_mins = (t_hrs - r_hrs) * 60;
    const r_mins = Math.round(t_mins);
    return isHours
      ? `${counter}: ${r_hrs}h ${r_mins}m remain`
      : `${counter}: ${counters[counter]} blocks remain`;
  };

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="max-w-xl rounded overflow-hidden shadow-lg p-4">
        <div className="sm:px-20 sm:py-10">
          <div className="mb-10">
            <div className="font-bold text-xl mb-2">Pomegranate Time</div>

            <p className="text-gray-700 text-base">
              Keep track of how many blocks you want to do for each task
              (Assumes 25min blocks). Tap each task to reduce the count. Reset
              the following day.
            </p>
          </div>

          {Object.keys(counters).map((counter, index) => (
            <button
              key={index}
              onClick={() => updateCounter(counter)}
              onMouseEnter={() => setIsShown(counter)}
              onMouseLeave={() => setIsShown("")}
              className="block focus:outline-none bg-gray-200 rounded-full px-4 py-2 font-semibold text-gray-700 mr-2 mt-2 text-md hover:bg-gray-300"
            >
              {isShown === counter
                ? `${counter}: Press to finish one`
                : getRepr(counter)}
            </button>
          ))}
          <div className="flex rounded-md shadow-sm mt-10">
            <input
              className={`${
                valError.text
                  ? `focus:border-red-600 focus:ring-red-600 border-red-600 border-6`
                  : `focus:ring-indigo-500 focus:border-indigo-500`
              } focus:outline-none  flex-1 block w-full border border-r rounded-r-none rounded-md border-gray-400 px-4 text-lg py-2 `}
              type="text"
              placeholder="Do Dissertation"
              value={name}
              onChange={(e) => updateName(e)}
            />
            <label htmlFor="time" className="sr-only"></label>
            <select
              onChange={(e) => setTime(e.target.value)}
              id="time"
              className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 inline-flex items-center px-3 rounded-none border border-gray-400 bg-gray-200 text-indigo-500 text-lg"
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((val) => (
                <option value={val} key={val}>{`${val}h`}</option>
              ))}
            </select>

            <button
              onClick={handleNew}
              className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500  inline-flex items-center px-3 rounded-r-md border border-gray-400 bg-gray-200 text-indigo-500 text-lg"
            >
              Add
            </button>
          </div>
          <div className="flex mt-4 items-end w-full justify-between items-baseline">
            <div>
              <div className="inline-block text-sm text-gray-600 mr-1">
                Hours:
              </div>
              <input
                id="comments"
                name="comments"
                type="checkbox"
                className="focus:ring-indigo-500 h-3 w-3 text-indigo-600 border-gray-300 rounded cursor-pointer"
                defaultChecked={isHours}
                onChange={(e) => handleToggle(e)}
              ></input>
            </div>
            <button
              type="button"
              className="focus:outline-none text-red-600 text-sm py-3 px-3 rounded-md hover:bg-red-100"
              onClick={resetCounters}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
